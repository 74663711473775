<template>
  <!-- Desc: Bidding Strategy View at the camapign level -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <div :class="!valuesAreChanged && 'u-spacing-pb-xxl'">
      <div
        data-cy="biddingStrategyHeader"
        class="u-spacing-pb-m u-font-size-5 u-font-weight-600"
      >
        Bidding Strategy:
      </div>
      <div
        v-for="(radioConfig, i) in tabMetadata.config.types"
        :key="i"
        class="u-spacing-pb-l"
      >
        <rb-radio
          v-model="selectedRadioConfig"
          class="bidding-strategy-entity-details"
          :native-value="radioConfig.value"
        >
          <div class="u-spacing-pb-s u-font-size-5">
            {{ radioConfig.title }}
          </div>
          <div class="u-color-grey-lighter u-display-flex u-flex-wrap-yes">
            <div>{{ radioConfig.desc }}</div>
          </div>
        </rb-radio>
      </div>
    </div>
    <div
      v-if="valuesAreChanged"
      class="u-spacing-pt-s"
    >
      <footerButtons
        :button-min-width="'u-min-width-100px'"
        @onClickBtnLeft="saveBiddingStrategy"
        @onClickBtnRight="cancel"
      />
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';

export default {
  components: {
    loader,
    footerButtons
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      selectedRadioConfig: null,
      getState: null,
      updateMetadata: null,
      requestPayload: null,
      tabConfig: null
    };
  },
  computed: {
    valuesAreChanged() {
      let value = false;
      if (this.selectedRadioConfig !== this.biddingStrategyValue) {
        value = true;
      }
      // to make bidding strategis readonly
      if (this.tabConfig?.hideFooterButton) {
        value = false;
      }
      return value;
    },
    campaignInfo() {
      return (this.getState && this.$store.getters[this.getState]) || {};
    },
    biddingStrategyValue() {
      return (
        this.campaignInfo?.data?.bidding?.strategy ||
        this.tabConfig.getBiddingStrategy(this.campaignInfo)
      );
    }
  },
  watch: {
    campaignInfo: {
      handler(newValue) {
        if (newValue?.status === 'loaded') {
          this.setSelectedRadioConfig();
        }
      },
      immediate: true
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
  },
  methods: {
    saveBiddingStrategy() {
      // todo update payload for API
      let oldBidStrategy = null;
      let bidStrategy = null;
      for (const item of this.tabMetadata.config.types) {
        if (item.value === this.selectedRadioConfig) {
          bidStrategy = item.title;
        } else if (item.value === this.biddingStrategyValue) {
          oldBidStrategy = item.title;
        }
      }
      const payload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':oldBidStrategy': oldBidStrategy,
        ':bidStrategy': this.selectedRadioConfig,
        ':entityId': this.entityId,
        ':pageUrl': window.location.href,
        ':viewBidStrategy': bidStrategy,
        ':campaignId': this.campaignInfo?.data?.campaignId?.toString()
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [payload],
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        entityName: {
          singular: 'Bidding Strategy',
          plural: 'Bidding Strategies',
          noCount: true
        },
        callAdgroupFetch: this.entityConfig.entityType === 'SD'
      });
    },
    setSelectedRadioConfig() {
      if (this.tabConfig?.getBiddingStrategy) {
        this.selectedRadioConfig = this.tabConfig.getBiddingStrategy(
          this.campaignInfo
        );
        return;
      }
      this.selectedRadioConfig = this.biddingStrategyValue || null;
    },
    cancel() {
      this.setSelectedRadioConfig();
    }
  }
};
</script>
<style lang="css">
.min-height-card {
  min-height: 630px;
}
.bidding-strategy-entity-details .rb-icon {
  align-self: baseline;
}
</style>
